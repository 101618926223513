<template>
  <div class="AddOrder">
    <el-page-header @back="back" content="新增订单"></el-page-header>
    <div class="f-w-b">
      <NavigationBar></NavigationBar>
      <div class="main">
        <div class="banner">
          {{ enterpriseStateInfo.enterpriseName }}
        </div>
        <div class="f-w boxShadow pt-40 pl-20 pr-20 mb-30" style="width: 95.5%;border-radius: 10px;">
          <el-form ref="AddOrderForm" :model="AddOrderForm" label-width="110px" class="Add-order-form pb-30 f-w"
            :rules="AddOrderRules">
            <div class="formTitle ">客户信息 </div>
            <el-form-item class="w--50" v-for="item of customerInfoList" :label="item.label" :prop="item.prop"
              :key="item.prop">
              <!-- 客户名称、联系人、详细地址 -->
              <div v-if="item.prop == 'customerName' || item.prop == 'contact' || item.prop == 'customerAddress'"
                class="customer-name-box f-c-b">
                <el-input v-if="item.inputType == 'textarea'" class="textarea fs-18" type="textarea" v-model="AddOrderForm[item.prop]"
                  :placeholder="item.placeholder" :maxlength="item.chinestMaxLength" clearable></el-input>
                <ChineseCharactersInput v-else :modelValue="AddOrderForm[item.prop]" :placeholderValue="item.placeholder"
                  :maxlength="'30'" @getValue="getValue" :type="item.prop">
                </ChineseCharactersInput>
                <el-button v-if="item.prop == 'customerName'" class="add-customer" round
                  @click="ChooseCustomerDialogShow = true"><i class="el-icon-circle-plus-outline"></i></el-button>
              </div>
              <!-- 联系电话 -->
              <PhoneInput v-else-if="item.prop == 'contactPhone'" :modelValue="AddOrderForm.contactPhone"
                :placeholderValue="'请输入联系电话'" @getPhoneNumber="getPhoneNumber" :LengthLimit="'16'" :maxlength="16">
              </PhoneInput>
              <!-- 地区 -->
              <ChooseCity v-else-if="item.prop == 'customerCity'" :city="AddOrderForm.customerCity"
                @handleChangeCity="handleChangeCity" :key="new Date().getTime()">
              </ChooseCity>
              <!-- 计价货币  -->
              <div class="f-c" v-else-if="item.prop == 'valuationCurrency'">
                <SelectOption class="w--100" :selectModel="AddOrderForm.valuationCurrency" :Option="CurrencyOption"
                  :type="'ValuationCurrency'" @selectValuationCurrency="selectValuationCurrency">
                </SelectOption>
                <div class="Currency_tips" v-show="(AddOrderForm.valuationCurrency != AddOrderForm.freightCurrency)">
                  与运费货币不一致
                </div>
              </div>
            </el-form-item>
            <div class="f-c-b w--100 mb-20">
              <div class="formTitle2 ">商品明细 </div>
              <div class="addCustomerBtn" @click="ChooseProductDialogShow = true"></div>
            </div>
            <ModifiableProductDetailTable class="mb-20"
              v-if="AddOrderForm.orderActiveDetailsList && AddOrderForm.orderActiveDetailsList.length"
              :List="AddOrderForm.orderActiveDetailsList" @changeNumberInput="changeNumberInput"
              @delectSelectProduct="delectSelectProduct" :checkProductCustomerId="checkProductCustomerId">
            </ModifiableProductDetailTable>
            <el-form-item class="w--50" v-for="item of commodityDetailsList" :label="item.label" :prop="item.prop"
              :key="item.prop">
              <!-- 运费货币  -->
              <div class="f-c" v-if="item.prop == 'freightCurrency'">
                <SelectOption style="position: relative;" :selectModel="AddOrderForm.freightCurrency"
                  :Option="CurrencyOption" :type="'FreightCurrency'" @selectFreightCurrency="selectFreightCurrency">
                </SelectOption>
                <div class="Currency_tips" v-show="(AddOrderForm.freightCurrency != AddOrderForm.valuationCurrency)">与计价货币不一致</div>
              </div>
              <el-input v-else type="number" v-model.trim="AddOrderForm[item.prop]" clearable
                :placeholder="item.placeholder"></el-input>
            </el-form-item>
            <div class="formTitle ">订单备注 </div>
            <TextArea class="fs-18" placeholderValue="请输入订单备注" id="myTextarea" :modelValue="AddOrderForm.orderNote" type="orderNote"
              @getTextAreaValue="getTextAreaValue" :maxlengthValue="300" :TextAreaisLimit="false"></TextArea>
            <div class="formTitle mt-20">操作备注 </div>
            <TextArea class="fs-18" placeholderValue="请输入操作备注" :modelValue="AddOrderForm.modifyNote" type="modifyNote"
              @getTextAreaValue="getTextAreaValue" :maxlengthValue="100" :TextAreaisLimit="false"></TextArea>
          </el-form>

        </div>
        <div class="f-c-c w--100 mb-40">
          <div class="btn" @click="addOrderActive('AddOrderForm')">创建订单</div>
        </div>
      </div>
    </div>
    <ChooseCustomerBox :ChooseCustomerDialogShow="ChooseCustomerDialogShow" @closeDialog="closeDialog"
      @selectedCustomer="selectedCustomer"></ChooseCustomerBox>
    <ChooseProductBox :ChooseProductDialogShow="ChooseProductDialogShow" @closeProductDialog="closeProductDialog"
      @selectedProduct="selectedProduct"></ChooseProductBox>
  </div>
</template>

<script>
import { CurrencyOption } from '@/utils/SelectOption'
import { AddOrderRules } from "@/utils/FormRules"
export default {
  name: 'AddOrder',
  components: {
    ChooseCustomerBox: () => import(/* webpackChunkName: "ChooseCustomerBox" */ "./components/ChooseCustomerBox.vue"),
    ChooseProductBox: () => import(/* webpackChunkName: "ChooseProductBox" */ "./components/ChooseProductBox.vue"),
    NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
  },

  data() {
    return {
      customerId: '',//原始客户ID
      customerName: '',//原始客户名称
      AddOrderForm: {
        customerId: '',//客户ID
        customerName: '',//客户名称
        contact: '',//联系人
        contactPhone: '',//联系电话
        customerCity: '',//地区
        customerAddress: '',//详细地址
        valuationCurrency: '￥',//计价货币
        realityAmount: 0,//订单总额
        autoAmount: 0,//订单总额自动计算
        freightCurrency: '￥',//运费货币
        freightAmount: 0,//运费金额
        orderNote: '',//订单备注
        modifyNote: '',//操作备注
        orderActiveDetailsList: [],//进行中订单属性对象集合
        orderId: '',//草稿ID,
        checkProductCustomerId: '',
      },
      customerInfoList: [
        {
          label: "客户名称：",
          prop: "customerName",
          placeholder: '请输入客户名称',
          chinestMaxLength: '30',
          inputType: 'textarea',
        }, {
          label: "联系人：",
          prop: "contact",
          placeholder: '请输入联系人',
          chinestMaxLength: '30',
          inputType: 'textarea',
        }, {
          label: "地区：",
          prop: "customerCity"
        }, {
          label: "联系电话:",
          prop: "contactPhone",
          placeholder: '请输入联系电话'
        }, {
          label: "详细地址：",
          prop: "customerAddress",
          placeholder: "如街道、门牌号、大厦、写字楼等",
          chinestMaxLength: '30',
          inputType: 'textarea',
        }, {
          label: "计价货币：",
          prop: "valuationCurrency"
        }
      ],
      commodityDetailsList: [

        {
          label: "订单总额：",
          prop: "realityAmount",
          placeholder: "请输入订单总额"
        }, {
          label: "运费货币",
          prop: "freightCurrency"
        }, {
          label: "运费金额：",
          prop: "freightAmount",
          placeholder: "请输入运费金额"
        }
      ],

      formItemList: [
        {
          label: "客户名称：",
          prop: "customerName",
          placeholder: '请输入客户名称'
        }, {
          label: "联系人：",
          prop: "contact",
          placeholder: '请输入联系人'
        }, {
          label: "联系电话:",
          prop: "contactPhone",
          placeholder: '请输入联系电话'
        }, {
          label: "地区：",
          prop: "customerCity"
        }, {
          label: "详细地址：",
          prop: "customerAddress",
          placeholder: "如街道、门牌号、大厦、写字楼等"
        }, {
          label: "计价货币：",
          prop: "valuationCurrency"
        }, {
          label: "商品明细",
          prop: "orderActiveDetailsList"
        }, {
          label: "订单总额：",
          prop: "realityAmount",
          placeholder: "请输入订单总额"
        }, {
          label: "运费货币",
          prop: "freightCurrency"
        }, {
          label: "运费金额：",
          prop: "freightAmount",
          placeholder: "请输入运费金额"
        }, {
          label: "订单单据备注：",
          prop: "orderNote",
          placeholder: "请输入订单备注"
        }, {
          label: "订单操作备注：",
          prop: "modifyNote",
          placeholder: "请输入操作备注"
        }
      ],
      AddOrderRules,
      CurrencyOption: CurrencyOption,//货币类型
      ChooseCustomerDialogShow: false,//是否显示客户列表盒子
      ChooseProductDialogShow: false,//是否显示产品列表盒子
      addProdectBtnDis: false,//是否可选择商品（必须先有客户名称）
      enterpriseStateInfo: {}
    }
  },
  created() {
    this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo');
    this.findOrderActiveNotes();
    this.$CommonRequests.getEnterpriseOrderTemplate('SA').then((res) => {
      this.AddOrderForm.orderNote = res.data.enterpriseNote
    })
  },
  methods: {
    // 获取客户的联系电话
    getPhoneNumber(data) {
      this.AddOrderForm.contactPhone = data.value
    },
    // 获取textarea内容
    getTextAreaValue(data) {
      this.AddOrderForm[data.type] = data.value
    },

    // 用户查看自己的订单草稿接口
    findOrderActiveNotes() {
      let that = this;
      that.$http.findOrderActiveNotes({
        lastModifyUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token')
      }).then(res => {
        if (res.data) {
          let { customerId, customerName, contact, contactPhone, customerCity, customerAddress, valuationCurrency, realityAmount2,
            freightCurrency, freightAmount, orderNote, modifyNote, orderId, autoAmount } = res.data;
          res.data.orderDetailsList.forEach(item => {
            item.isSelected = true;
            item.visibleProductName = false;//是否显示品名popover
            item.visibleProductNumber = false;//是否显示编号popover
            item.visibleUnitPrice = false;//是否显示价格popover
            item.visibleInventoryQuantigy = false;//是否显示库存popover
            item.originUnitPrice = item.unitPrice;
            item.LastTransactionPrice = '';
            item.inventoryQuantity = '';
          })
          that.AddOrderForm = {
            customerId,
            customerName,
            contact,
            contactPhone,
            customerCity: customerCity ? that.$common.splitCityArr(customerCity) : '',
            customerAddress,
            valuationCurrency,
            autoAmount,
            realityAmount: realityAmount2,
            freightCurrency,
            freightAmount,
            orderNote,
            modifyNote,
            orderActiveDetailsList: res.data.orderDetailsList,
          };
          that.customerId = customerId;
          that.customerName = customerName;
          that.orderId = orderId;
          that.checkProductCustomerId = customerId;

          that.$setStorage('chooseCustomerId', customerId)
          this.$setStorage('selectProductList', res.data.orderDetailsList)
        }
      })
    },
    // 如果用户是选中客户的话，会有客户id，如果用户将选中的用户名称改变，那么客户id为空
    changeCustomer(data) {
      if (this.customerName != data.value) {
        this.AddOrderForm.customerId = '';
        this.checkProductCustomerId = ''
        this.$removeStorage('chooseCustomerId')
      } else {
        this.AddOrderForm.customerId = this.customerId;
        this.checkProductCustomerId = this.customerId
        this.$setStorage('chooseCustomerId', this.customerId)
      }
      this.AddOrderForm.customerName = data.value
    },
    getValue(data) {
      data.type == 'customerName' ? this.changeCustomer(data) : this.AddOrderForm[data.type] = data.value
    },
    // 获取传递的参数
    getParameter() {
      let that = this,
        { customerId, customerName, contact, contactPhone, customerCity, customerAddress, autoAmount,
          realityAmount, valuationCurrency, orderNote, freightAmount, freightCurrency, modifyNote, orderActiveDetailsList } = that.AddOrderForm;
      return {
        lastModifyUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        customerName,//客户名称
        contact,//联系人
        contactPhone,//客户联系电话
        customerCity: customerCity ? that.$common.checkCityArr(customerCity) : '',
        customerAddress: customerAddress,//客户地址
        autoAmount: Number(autoAmount),//订单自动计算
        realityAmount: Number(realityAmount),//订单实际金额
        valuationCurrency,//计价货币
        returnFlag: 'N',//退货状态Y（有退货）、N（无退货）
        orderNote,//订单备注
        customerId,//客户ID
        freightAmount: Number(freightAmount),//运费金额
        freightCurrency,//运费货币:人民币（￥）、USD($)
        modifyNote,//操作备注
        orderDetailsList: orderActiveDetailsList,
        orderId: that.orderId,//订单id(如果有草稿订单需要草稿订单id)
      }
    },
    // 返回上一页
    back() {
      let that = this,
        data = that.getParameter();
      that.$confirm('保留本次编辑？', '提示').then(() => {
        that.$http.addReviseOrderActive(data).then(() => {
          that.$router.go(-1)
        })
      }).catch(() => {
        that.$router.go(-1)
      })
    },
    refresh() {
      this.reload();
    },
    // 关闭选择客户盒子
    closeDialog(data) {
      this.ChooseCustomerDialogShow = data
    },
    // 选中客户
    selectedCustomer(data) {
      this.AddOrderForm.customerId = data.customerId;
      this.AddOrderForm.customerName = data.customerName;
      this.AddOrderForm.contact = data.contact;
      this.AddOrderForm.contactPhone = data.customerPhone;
      this.AddOrderForm.customerCity = this.$common.splitCityArr(data.customerCity);
      this.AddOrderForm.customerAddress = data.customerAddress;
      this.ChooseCustomerDialogShow = false;
      this.customerName = data.customerName;
      this.customerId = data.customerId;
      this.$setStorage('chooseCustomerId', data.customerId)
      this.checkProductCustomerId = data.customerId
      this.$refs["AddOrderForm"].clearValidate(['customerName', 'contactPhone', 'customerCity', 'customerAddress'])
    },
    // 关闭产品列表盒子
    closeProductDialog(data) {
      this.ChooseProductDialogShow = data
    },
    // 选中产品
    selectedProduct(data) {
      let that = this,
        list = that.AddOrderForm.orderActiveDetailsList,
        priseTotal = 0;
      // 从data中提取valuationCurrency, unitPrice, productName，剩下的参数给newData
      // 这种写法 直接使用newData可能会导致忘记添加回提取出来的数据！！！
      // { valuationCurrency, unitPrice, productName, ...newData } = data;
      // { ...newData } = data;
      // 选中当前商品的货币单位和外部规定的货币单位不一致则默认重置
      if (data.valuationCurrency != this.AddOrderForm.valuationCurrency) {
        that.ChooseProductDialogShow = false;//隐藏产品列表盒子
        that.$confirm(`${data.productName}与当前的结算货币类型不一致，默认将其单价置零，请手动设置该商品单价`, '提示', {
          showCancelButton: false,
          distinguishCancelAndClose: true,
          closeOnClickModal: false,//不可通过点击遮罩关闭
          closeOnPressEscape: false//不可通过按下 ESC 键关闭
        }).then(() => {
          data.unitPrice = Number(0)
          data.amount = Number(0)
          data.auAmount = Number(0)
          data.quantity = Number(1)
          list.push(data);
          that.$setStorage('selectProductList', list)
        }).catch(() => { })
        //如果选中商品没有价格则提示需要手动输入价格
      } else if (!data.unitPrice) {
        that.ChooseProductDialogShow = false;//隐藏产品列表盒子
        that.$confirm(`“${data.productName}”暂无报价，默认将其单价置零，请手动设置该商品单价`, '提示', {
          showCancelButton: false,
          distinguishCancelAndClose: true,
          closeOnClickModal: false,//不可通过点击遮罩关闭
          closeOnPressEscape: false//不可通过按下 ESC 键关闭
        }).then(() => {
          data.unitPrice = Number(0)
          data.amount = Number(0)
          data.auAmount = Number(0)
          data.quantity = Number(1)
          list.push(data);
          that.$setStorage('selectProductList', list)
        }).catch(() => { })
      }
      else {
        list.push(data);
        that.$setStorage('selectProductList', list)
        that.ChooseProductDialogShow = false;//隐藏产品列表盒子
      }
      list.forEach((item, index) => {
        priseTotal += list[index].amount
      })
      that.AddOrderForm.orderActiveDetailsList = list;
      that.AddOrderForm.autoAmount = priseTotal.toFixed(2);
      that.AddOrderForm.realityAmount = priseTotal.toFixed(2);
      this.$refs["AddOrderForm"].clearValidate(['orderActiveDetailsList'])
    },
    // 改变数组输入框
    changeNumberInput(currentValue, productId, type) {
      let list = this.AddOrderForm.orderActiveDetailsList,
        priseTotal = 0;
      currentValue = currentValue ? currentValue : 0;

      list.forEach((item, index) => {
        if (item.productId == productId) {
          // 改变产品价格
          if (type == 'changeUnitPrice') {
            this.AddOrderForm.orderActiveDetailsList[index].amount = item.quantity * currentValue ? item.quantity * currentValue : 0;
            this.AddOrderForm.orderActiveDetailsList[index].auAmount = item.quantity * currentValue ? item.quantity * currentValue : 0;
          }
          //改变数量
          if (type == 'changeQuantity') {
            this.AddOrderForm.orderActiveDetailsList[index].amount = item.unitPrice * currentValue ? item.unitPrice * currentValue : 0;
            this.AddOrderForm.orderActiveDetailsList[index].auAmount = item.unitPrice * currentValue ? item.unitPrice * currentValue : 0;
          }
          // 改变小计
          if (type == 'changeRealAmount') {
            this.AddOrderForm.orderActiveDetailsList[index].amount = currentValue
          }
        }
        priseTotal += list[index].amount
      })
      this.AddOrderForm.autoAmount = priseTotal.toFixed(2);
      this.AddOrderForm.realityAmount = priseTotal.toFixed(2);
      this.AddOrderForm.orderActiveDetailsList = list;
      this.$setStorage('selectProductList', list)
    },
    // 删除选中的产品
    delectSelectProduct(productId) {
      let arr = this.AddOrderForm.orderActiveDetailsList.filter(item => item.productId != productId);
      let priseTotal = 0;
      arr.forEach((item, index) => {
        priseTotal += arr[index].amount
      })
      this.AddOrderForm.autoAmount = priseTotal.toFixed(2);
      this.AddOrderForm.realityAmount = priseTotal.toFixed(2);
      this.AddOrderForm.orderActiveDetailsList = arr;
      this.$setStorage('selectProductList', arr)
    },

    // 选中省市区
    handleChangeCity(value) {
      this.AddOrderForm.customerCity = value;
    },
    // 选择计价货币
    selectValuationCurrency(value) {
      this.AddOrderForm.valuationCurrency = value;
    },
    // 选择运费货币
    selectFreightCurrency(value) {
      this.AddOrderForm.freightCurrency = value;
    },

    // 创建订单
    addOrderActive(form) {
      let that = this,
        data = that.getParameter();
      that.$refs[form].validate((valid) => {
        if (valid) {
          if (that.AddOrderForm.orderActiveDetailsList.length == 0) {
            return that.$common.message('请选择商品！', 'warning')
          }
          that.$confirm('创建新订单？', '提示').then(() => {
            that.$http.addOrderActive(data).then(res => {
              if (res.code == 200) {
                that.$message({ message: res.msg, type: 'success' });
                // that.$common.DelayedBack();
                that.$router.push({
                  name: 'OrderList', query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                      type: 'All'
                    }))
                  }
                })
              }
            })
          }).catch(() => {

          })

        }
      });
    }

  },
  beforeDestroy() {
    this.$removeStorage('AddOrderProductList');
    this.$removeStorage('selectProductList');
    this.$removeStorage('findOrderCompletedUnitPriceInfo');
    this.$removeStorage('customerList');
    this.$removeStorage('chooseCustomerId')
  }
}

</script>
<style lang='less' scoped>
.AddOrder {
  .main {
    width: 980px;

    .banner {
      background: url('../../../assets/supply/supplyBanner.png');
      height: 100px;
      color: #FFFFFF;
      font-size: 30px;
      line-height: 100px;
      text-align: center;
      margin-bottom: 40px;
    }

    .Add-order-form {
      .formTitle {
        width: 100%;
        margin-bottom: 22px;
        border-left: 4px solid #0363FA;
        padding-left: 10px;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
      }
      .textarea {
        .el-input__inner{
            font-size: 18px !important;
        }
      }

      .formTitle2 {
        // width: 100%;
        // margin-bottom: 22px;
        border-left: 4px solid #0363FA;
        padding-left: 10px;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
      }

      .Currency_tips {
        position: absolute;
        top: 35px;
        color: red;
      }

      .addCustomerBtn {
        width: 60px;
        height: 50px;
        background: url('../../../assets/supply/addCustomerBtn.png');
        cursor: pointer;
      }
    }

    .btn {
      width: 160px;
      height: 50px;
      background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
      border-radius: 10px;
      color: #FFF;
      font-size: 16px;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
    }
  }






  // .Add-order-form {
  //   /deep/ .el-form-item__label {
  //     text-align: center;
  //   }

  //   .Currency_tips {
  //     margin-left: 50px;
  //     color: red;
  //   }

  //   .customer-name-box {
  //     .add-customer {
  //       padding: 10px 20px;
  //       border-radius: 50px;
  //       margin-left: 30px;

  //       .el-icon-circle-plus-outline {
  //         font-size: 22px;
  //       }
  //     }
  //   }

  //   .add-btn {
  //     text-align: right;

  //     .add-customer1 {
  //       padding: 10px 20px;
  //       border-radius: 50px;
  //       margin-bottom: 10px;

  //       .el-icon-circle-plus-outline {
  //         font-size: 22px;
  //       }


  //     }
  //   }



  // }
}
</style>
